import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"
import { navigate } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { InPageNav } from "../../components/navbar.js"

import {
  HeroTitleH1,
  SectionTitle,
  SectionDescription,
} from "../../components/typography.js"
import { Section } from "../../components/sections/sections.js"

const Row = styled(Section)`
  padding-top: 70px;
  padding-bottom: 70px;
`

const RowContent = styled.div`
  opacity: 0.1;
  transition: opacity 0.3s ease-in;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`
const Title = styled(SectionTitle)`
  font-size: 500;
`
const NumberTitle = styled(Title)`
  font-size: 94px;
  font-weight: 600;
`

const howClassWorksContent = [
  {
    title: "Getting started",
    description:
      "Starting your course is easy. We first get you onboarded - filling out forms and getting the logistical stuff out of the way. ",
  },
  {
    title: "Getting personal",
    description:
      "We hate to be bores, and our team wants to know you! We'll set up a personal chat between you and all the instructors so we can easily stay in touch! We do informal office hours, but in general you can ask a question anytime. We won't get back to you immediately, but our response times are pretty fast during course times.",
  },
  {
    title: "Figuring out the problem areas",
    description:
      "You will start the prep. by taking the CASPer's official practice test, likely untimed, so we can see your responses and isolate areas that need fixing. It's completely okay if you're starting from scratch, and don't know anything about the test - we don't expect you to :). ",
  },
  {
    title: "Taking up the diagnostic test",
    description:
      "We then discuss your answers with you and show you the areas that we think need to be fixed. It's more-so an informal chat about what could've been said, or should've been avoided if this were the actual test - just so you have a sense of why we're working on the things we're working on.",
  },
  {
    title: "Perrrsonlized",
    description:
      "So, this may sound a bit crazy - but we go through your experiences and ask you to compile them and pull out specific insights, and specific situations that you can both relate to, and can use as examples in your answers. Simply, this makes your answers richer than other students, and it sounds more realisitic and natural than responding purely theoretically.",
  },
  {
    title: "Learning the stuff",
    description:
      "You will be going through assorted video lectures or readings, based on your instructor's assessment of you. These readings will cover foundational topics like ethics, personal relationships, how to act, what to say in given situations. This is also the time when you'll learn how to respond to different question types.",
  },
  {
    title: "Frameworks",
    description:
      "Okay, so you know the stuff at this point. Now, we focus on how to make the best use of it for every question. For this, we give you frameworks that shape your thought process which results in a better formulated reponses. In other words, this step saves you time on the real test so that you don't have to think about each question and wonder how you should put your thoughts into words.",
  },
  {
    title: "Practice questions",
    description:
      "Once you know the content and the framework - it's time to apply it. We don't like to learn via practice - i.e. we don't think that taking a million practice tests helps you learn in the most efficient manner, so ask you to apply everything you know on your first practice test. If you miss something, or you're incorrect about another thing - we'll tell you to go back and fix your stuff. Other than that, we'll give you feedback on what you've done great and what we need to work on.",
  },
  {
    title: "Time cutting",
    description:
      "So far, everything we've done has been untimed - so that we're not constraining ourselves in anyway (#boundless!). At this point, we'll give you tips on how to get faster, and where to save time. Pretty straight-forward, right? ",
  },
  {
    title: "Mocking",
    description:
      "We'll be mocking you (... um, no not that way!) but we'll build up to things. First, you'll go through the 'quarter-lengths', which are literally quarter the length of a full test. Why the shorter version? Just in case your stamina isn't the best, or there's something that needs immediate fixing; we wouldn't don't want to waste time if you weren't exactly doing it right, you know? Once this is done, then we're off to the full length test. As before, if something needs fixing, we'll do it again here. If not, then you're good to sit your test!",
  },
]
const HowItWorks = () => {
  const [positions, setPositions] = useState([])
  const [currentHighlight, setHighlight] = useState(0)
  const itemRefs = useRef([])

  useEffect(() => {
    let positionsArray = itemRefs.current.map((el, index) => {
      if (typeof window !== "undefined") {
        return window.pageYOffset + el.getBoundingClientRect().top
      }
    })
    setPositions(positionsArray)
  }, [])

  const handleRefs = (el, index) => {
    if (itemRefs.current.length < howClassWorksContent.length)
      itemRefs.current.push(el)
  }

  const positionLocator = (
    currPos,
    scrollingDownBool,
    upperRange,
    lowerRange
  ) => {
    positions.forEach((row, index) => {
      while (
        positions[index] &&
        positions[index] > upperRange &&
        positions[index] < lowerRange
      ) {
        setHighlight(index)
        itemRefs.current[currentHighlight].style.opacity = "1"
        if (
          index - 1 > -1 &&
          itemRefs.current[index - 1].style.opacity === "1"
        ) {
          itemRefs.current[index - 1].style.opacity = "0.1"
        }
        if (
          index + 1 < positions.length &&
          itemRefs.current[index + 1].style.opacity === "1"
        ) {
          itemRefs.current[index + 1].style.opacity = "0.1"
        }
        break
      }
    })
  }
  useScrollPosition(({ prevPos, currPos }) => {
    if (typeof window !== "undefined") {
      let currX = Math.abs(currPos.y)
      let prevX = Math.abs(prevPos.y)
      let upperRange =
        window.innerHeight * 0.3 || document.documentElement.clientHeight * 0.3
      let lowerRange =
        window.innerHeight * 0.7 || document.documentElement.clientHeight * 0.7
      // let currentItemPos = itemRefs.current[
      //   currentHighlight
      // ].getBoundingClientRect().top
      positionLocator(
        currX,
        currX > prevX,
        currX + upperRange,
        currX + lowerRange
      )
    }
  })
  const RedirectToPage = dest => {
    navigate(dest)
  }
  return (
    <Layout>
      <SEO title="How 99point9 MCAT Course Works" />
      <InPageNav
        pageTitle="How It Works"
        navItems={[
          { link: "/casper", label: "Overview" },
          { link: "/casper/how_it_works", label: "How It Works" },
          { link: "/casper/schedule", label: "Class Schedule" },
          { link: "/casper/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />

      <Section>
        <HeroTitleH1 align="center" float="center">
          HOW IT WORKS
        </HeroTitleH1>
      </Section>
      <br />
      <br />
      <br />
      <br />

      {howClassWorksContent.map((item, index) => {
        return (
          <Row
            key={"row" + item.title + item.description + index}
            wide
            backgroundColor="rgb(230,230,230)"
          >
            <RowContent
              ref={el => handleRefs(el, index)}
              className={"animatedRow " + index}
            >
              <div>
                <Title>{item.title}</Title>
                <SectionDescription>{item.description}</SectionDescription>
              </div>
              <div>
                <NumberTitle>{index + 1}</NumberTitle>
              </div>
            </RowContent>
          </Row>
        )
      })}
    </Layout>
  )
}

export default HowItWorks
